import React, { FunctionComponent } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import Section from "../../Section";
import RichText from "../../RichText";
import CtaBlock from "../../CtaBlock";

import { ImageInterface, SanityCtaBlock } from "web/common/src/types/SanityTypes";
import { IMAGE_SIZES } from "../../../constants";

import "./styles.scss";

interface BluePrintApplyNowInterface {
  palleteColorList?: {
    bgColor: {
      value: string;
      title: string;
    };
  };
  _rawDescription: any;
  ctaLink: SanityCtaBlock;
  title: string;
  image: ImageInterface;
}

const BluePrintApplyNow: FunctionComponent<BluePrintApplyNowInterface> = props => {
  const { palleteColorList, _rawDescription, ctaLink, title, image } = props;
  return (
    <Section sectionClassName="c-bluePrint-applynow" theme={palleteColorList?.bgColor.title}>
      <div className="grid-row grid-row-vertical-center">
        <div className="grid-col grid-col-12 grid-col-md-5">
          <div className="is-sticky">
            <h2 className="c-bluePrint-applynow__title">{title}</h2>
            {ctaLink?.ctaLabel && (
              <div className="c-bluePrint-applynow__cta">
                <CtaBlock ctaBlock={ctaLink} ctaLabel={ctaLink.ctaLabel} />
              </div>
            )}
            <div className="c-bluePrint-applynow__image">
              <SanityImage
                {...image}
                sizes={IMAGE_SIZES.IMAGE_TEXT_BLOCK}
                width={553}
                height={513}
                htmlWidth={553}
                htmlHeight={513}
                className={`c-bluePrint-applynow__image-img`}
              />
            </div>
          </div>
        </div>
        <div className="grid-col grid-col-12 grid-col-md-7">
          <RichText data={_rawDescription} />
        </div>
      </div>
    </Section>
  );
};

export default BluePrintApplyNow;
